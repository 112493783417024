import React, { createContext, useState, useEffect } from 'react';
import { post } from '../adapters/xhr';

const context = createContext(null);

const AuthProvider = ({ children }) => {
  const [account, setAccount] = useState({ auth: false, user: null });

  useEffect(async () => {
    const { data } = await post('/api/auth');
    setAccount(data);
  }, []);

  return (
    <context.Provider value={{ account, setAccount }}>
      { children }
    </context.Provider>
  );
};

AuthProvider.context = context;

export default AuthProvider;
