import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';

const ModalWrapper = ({ children }) => {
  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);

  if (show) {
    return (
      <Modal
        show={show}
        onHide={handleClose}
        centered={true}
        dialogClassName="modal-panel"
      >
        {children}
      </Modal>
    );
  }

  return <Navigate to="/" />;
};

export default ModalWrapper;
