import React, { useEffect, useState } from 'react';
import {
  Button, Col, Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { get } from '../adapters/xhr';

const RankingPlayers = () => {
  const { t } = useTranslation();

  const [players, setPlayers] = useState([]);
  const [filteredPlayers, setFilteredPlayers] = useState([]);
  const [searchName, setSearchName] = useState('');

  const handleSearch = () => {
    setFilteredPlayers(players
      .filter((item) => item.name.toLowerCase().includes(searchName.toLowerCase())));
  };

  const getData = async () => {
    const { data } = await get('/assets/cache/players.json');

    const newData = data.map((item, index) => ({
      ...item,
      empire: item.empire === 3 ? <span style={{ color: '#3f99c9' }}>{t('blue')}</span>
        : item.empire === 2 ? <span style={{ color: '#cbca65' }}>{t('yellow')}</span>
          : <span style={{ color: '#d13348' }}>{t('red')}</span>,
      rank: `${index + 1}.`,
      key: index,
    }));

    setPlayers(newData);
    setFilteredPlayers(newData.filter((item) => item.name.toLowerCase()
      .includes(searchName.toLowerCase())));
  };

  useEffect(async () => { await getData(); }, []);
  useEffect(() => { handleSearch(); }, [searchName]);

  const columns = [
    { dataField: 'rank', text: t('rank') },
    { dataField: 'name', text: t('name') },
    { dataField: 'empire', text: t('empire') },
    { dataField: 'level', text: t('evo') },
  ];

  return (
    <>
      <Row>
        <Col>
          <div className="input-group d-flex justify-content-around">
            <div className="input-group w-50">
              <input type="text" className="form-control dark-form-control" placeholder={t('search-by-playername')} onChange={(e) => { setSearchName(e.target.value); }} />
              <Button variant="warning" onClick={() => {
                toast(t('start-typing-search'));
              }}>
                <FontAwesomeIcon icon={faSearch} />
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="text-center">
          <BootstrapTable noDataIndication={() => t('no-data-to-display')}
            bordered={false}
            bootstrap4={true}
            classes='table-data'
            headerClasses='color-gold'
            bodyClasses='border-0'
            keyField='key'
            data={filteredPlayers}
            columns={columns}
            pagination={paginationFactory({ prePageText: t('previous'), nextPageText: t('next'), hideSizePerPage: true })}
          />
        </Col>
      </Row>
    </>
  );
};

export default RankingPlayers;
