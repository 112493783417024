import React, { useEffect, useState } from 'react';
import {
  Button, Col, Form, ListGroup, Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import {
  deleteReq, get, patch, post,
} from '../adapters/xhr';

const AdminNewsSettings = () => {
  const { t } = useTranslation();

  const [news, setNews] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [file, setFile] = useState(null);

  const [newTitle, setNewTitle] = useState('');
  const [newContent, setNewContent] = useState('');

  const getNews = async () => {
    const { data } = await get('/api/admin/news');

    const newData = data.map((item) => ({
      ...item,
      date: new Date(item.date).toUTCString(),
    }));

    setNews(newData);
  };

  useEffect(async () => { await getNews(); }, []);

  const addNewNews = async () => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('title', title);
      formData.append('content', content);

      await post('/api/admin/news', formData);

      toast.success(t('add-news-success'));
      await getNews();
    } catch (error) {
      toast.error(t('add-news-error'));
    }
  };

  const deleteNews = async (id) => {
    try {
      await deleteReq('/api/admin/news', {
        data: { id },
      });

      toast.success(t('delete-news-success'));
      await getNews();
    } catch (error) {
      toast.error(t('delete-news-error'));
    }
  };

  const updateNewsTitle = async (id) => {
    try {
      await patch('/api/admin/news/title', {
        id,
        title: newTitle,
      });

      toast.success(t('update-title-success'));
      await getNews();
    } catch (e) {
      toast.error(t('update-title-error'));
    }
  };

  const updateNewsContent = async (id) => {
    try {
      await patch('/api/admin/news/content', {
        id,
        content: newContent,
      });

      toast.success(t('update-content-success'));
      await getNews();
    } catch (e) {
      toast.error(t('update-content-error'));
    }
  };

  const updateNewsImage = async (newFile, id) => {
    const formData = new FormData();
    formData.append('file', newFile);
    formData.append('id', id);

    try {
      await patch('/api/admin/news/image', formData);

      toast.success(t('update-image-success'));
    } catch (e) {
      toast.error(t('update-image-error'));
    }
  };

  const columns = [
    { dataField: 'id', text: 'ID' },
    { dataField: 'title', text: t('title') },
    { dataField: 'date', text: t('date') },
  ];

  const expandRow = {
    // eslint-disable-next-line react/display-name
    renderer: (row) => (
      <ListGroup className="text-left">
        <ListGroup.Item className="d-flex justify-content-between dark-input-group">
          <div className="w-100">
            <h5 className="mb-1">{t('change-title')}</h5>
            <p className="mb-1">{t('action-instant-effect')}</p>
          </div>
          <div className="input-group align-self-center w-75">
            <input type="text" className="form-control dark-form-control" placeholder={t('new-title')}
                   onChange={(e) => { setNewTitle(e.target.value); }}/>
            <Button size="sm" className="default-button" onClick={ async () => { await updateNewsTitle(row.id); }}>{t('submit')}</Button>
          </div>
        </ListGroup.Item>

        <ListGroup.Item className="d-flex justify-content-between dark-input-group">
          <div className="w-100">
            <h5 className="mb-1">{t('change-content')}</h5>
            <p className="mb-1">{t('action-instant-effect')}</p>
          </div>
          <div className="input-group align-self-center w-75">
            <Form.Control className="dark-form-control" size="sm" as="textarea" rows={5}
                          onChange={(e) => { setNewContent(e.target.value); }} />
            <Button size="sm" className="default-button" onClick={ async () => { await updateNewsContent(row.id); }}>{t('submit')}</Button>
          </div>
        </ListGroup.Item>

        <ListGroup.Item className="d-flex justify-content-between dark-input-group">
          <div className="w-100">
            <h5 className="mb-1">{t('change-image')}</h5>
            <p className="mb-1">{t('action-instant-effect')}</p>
          </div>
          <div className="input-group align-self-center w-75">
          <Form.File onChange={(e) => { updateNewsImage(e.target.files[0], row.id); }} />
          </div>
        </ListGroup.Item>

        <ListGroup.Item className="d-flex justify-content-between dark-input-group">
          <div className="w-100">
            <h5 className="mb-1">{t('news-delete')}</h5>
            <p className="mb-1">{t('permanent-action')}</p>
          </div>
          <Button size="sm" className="default-button align-self-center" onClick={ async () => { await deleteNews(row.id); }}>{t('delete')}</Button>
        </ListGroup.Item>
      </ListGroup>
    ),
    onlyOneExpanding: true,
    expandByColumnOnly: true,
    expandColumnPosition: 'right',
    showExpandColumn: true,
    // eslint-disable-next-line react/display-name
    expandColumnRenderer: ({ expandable }) => (expandable
      && <Button size="sm" className="default-button" onClick={onclick}>
        {t('edit')}
      </Button>),
    // eslint-disable-next-line react/display-name
    expandHeaderColumnRenderer: () => (
      <div onClick={(e) => { e.stopPropagation(); }}>{t('actions')}</div>
    ),
  };

  return (
    <>
      <Row className="mb-3">
        <Col className="form-bordered-gold text-center">
          {showForm ? <Form>
            <Form.Group as={Row}>
              <Form.Label column sm={6}>
                {t('title')}
              </Form.Label>
              <Col sm={6}>
                <Form.Control className="dark-form-control" size="sm" onChange={(e) => { setTitle(e.target.value); }} />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={6}>
                {t('content')}
              </Form.Label>
              <Col sm={6}>
                <Form.Control className="dark-form-control" size="sm" as="textarea" rows={10}
                              onChange={(e) => { setContent(e.target.value); }} />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={6}>
                {t('image')}
              </Form.Label>
              <Col sm={6}>
                <Form.File onChange={(e) => { setFile(e.target.files[0]); }} />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="text-center">
              <Col sm={12}>
                <Button size="sm" className="default-button" onClick={ async () => { await addNewNews(); setShowForm(false); }}>
                  {t('submit')}
                </Button>
              </Col>
            </Form.Group>
          </Form> : <Button size="sm" className="default-button" onClick={() => { setShowForm(true); }}>
            {t('add-news')}
          </Button>}
        </Col>
      </Row>
      <Row>
        <Col className="text-center">
          <BootstrapTable noDataIndication={() => t('no-data-to-display')}
            bordered={false}
            bootstrap4={true}
            classes='table-data'
            headerClasses='color-gold'
            bodyClasses='border-0'
            keyField='id'
            data={news}
            columns={columns}
            expandRow={ expandRow }
            pagination={paginationFactory({ prePageText: t('previous'), nextPageText: t('next'), hideSizePerPage: true })}
          />
        </Col>
      </Row>
    </>);
};

export default AdminNewsSettings;
