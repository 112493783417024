import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import {
  Button, Col, Form, InputGroup, Row,
} from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { patch } from '../adapters/xhr';

const initialUser = {
  oldPassword: '',
  password: '',
  passwordVerify: '',
};

const UserChangePassword = () => {
  const { t } = useTranslation();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const [visiblePassword, setVisiblePassword] = useState(false);

  const performChangePassword = async (values, token) => {
    const { oldPassword, password } = values;

    await patch('/api/user/account/password', {
      oldPassword,
      newPassword: password,
      token,
    });
  };

  const initChangePassword = async (values) => {
    if (!executeRecaptcha) {
      return;
    }

    const token = await executeRecaptcha('change_chardel_password');
    await performChangePassword(values, token);
  };

  const validationSchema = yup.object().shape({
    oldPassword: yup
      .string()
      .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,16}$/, t('password-match'))
      .min(8, t('password-min'))
      .max(16, t('password-max'))
      .required(t('field-required')),
    password: yup
      .string()
      .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,16}$/, t('password-match'))
      .min(8, t('password-min'))
      .max(16, t('password-max'))
      .required(t('field-required')),
    passwordVerify: yup
      .string()
      .oneOf(
        [yup.ref('password'), null],
        t('passwords-not-match'),
      )
      .required(t('field-required')),
  });

  return (
    <Row className="mt-4">
      <Col className="d-flex justify-content-center">
        <Formik
          initialValues={initialUser}
          validationSchema={validationSchema}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            await toast.promise(
              initChangePassword(values),
              {
                loading: t('loading'),
                success: t('reset-password-success'),
                error: t('reset-password-error'),
              },
            );

            resetForm();
            setSubmitting(false);
          }}
        >
          {({
            touched,
            errors,
            isSubmitting,
            handleSubmit,
            handleChange,
          }) => (
        <Form className="py-2 text-center" onSubmit={handleSubmit}>
          <Form.Group controlId="oldPassword" className='py-3'>
            <Form.Label size="sm">{t('old-password')}</Form.Label>
            <InputGroup>
              <Form.Control
                className={touched.oldPassword && errors.oldPassword ? 'dark-form-control error' : 'dark-form-control'}
                size="sm"
                type={visiblePassword ? 'text' : 'password'}
                placeholder={t('old-password')}
                onChange={handleChange}
              />
              <InputGroup.Text className='form-faicon'>
                <FontAwesomeIcon
                  icon={visiblePassword ? faEye : faEyeSlash}
                  onClick={() => { setVisiblePassword(!visiblePassword); }}
                />
              </InputGroup.Text>
            </InputGroup>
            {(touched.oldPassword && errors.oldPassword) && <div className="error-message">{errors.oldPassword}</div>}
          </Form.Group>

          <Form.Group controlId="password" className='py-3'>
            <Form.Label size="sm">{t('password')}</Form.Label>
            <InputGroup>
              <Form.Control
                className={touched.password && errors.password ? 'dark-form-control error' : 'dark-form-control'}
                size="sm"
                type={visiblePassword ? 'text' : 'password'}
                placeholder={t('password')}
                onChange={handleChange}
              />
              <InputGroup.Text className='form-faicon'>
                <FontAwesomeIcon
                  icon={visiblePassword ? faEye : faEyeSlash}
                  onClick={() => { setVisiblePassword(!visiblePassword); }}
                />
              </InputGroup.Text>
            </InputGroup>
            {(touched.password && errors.password) && <div className="error-message">{errors.password}</div>}
          </Form.Group>

          <Form.Group controlId="passwordVerify" className='py-3'>
            <Form.Label>{t('password-verify')}</Form.Label>
            <InputGroup>
              <Form.Control
                className={touched.passwordVerify && errors.passwordVerify ? 'dark-form-control error' : 'dark-form-control'}
                size="sm"
                type={visiblePassword ? 'text' : 'password'}
                placeholder={t('password-verify')}
                onChange={handleChange}
              />
              <InputGroup.Text className='form-faicon'>
                <FontAwesomeIcon
                  icon={visiblePassword ? faEye : faEyeSlash}
                  onClick={() => { setVisiblePassword(!visiblePassword); }}
                />
              </InputGroup.Text>
            </InputGroup>
            {(touched.passwordVerify && errors.passwordVerify) && <div className="error-message">{errors.passwordVerify}</div>}
          </Form.Group>

          <Button type="submit" size="sm" className="default-button" disabled={isSubmitting}>
            {t('change-password')}
          </Button>
        </Form>
          )}
        </Formik>
      </Col>
    </Row>
  );
};

export default UserChangePassword;
