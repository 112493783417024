import React from 'react';
import { Carousel, Image } from 'react-bootstrap';

import './Carousel.scss';
import carousel2 from '../assets/images/sliders/slider1.gif';
import carousel3 from '../assets/images/sliders/slider2.gif';

const Slider = () => (
  <Carousel fade>
    <Carousel.Item>
      <Image
        className="d-block w-100"
        src={carousel2}
      />
    </Carousel.Item>
    <Carousel.Item>
      <Image
        className="d-block w-100"
        src={carousel3}
      />
    </Carousel.Item>
  </Carousel>
);

export default Slider;
