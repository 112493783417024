import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { motion } from 'framer-motion';

const Terms = () => (
  <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
    <Container>
      <Row>
        <Col>
          Coming soon...
        </Col>
      </Row>
    </Container>
  </motion.div>
);

export default Terms;
