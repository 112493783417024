import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';
import { Col, Container, Row } from 'react-bootstrap';
import { motion } from 'framer-motion';

import NewsDetailed from './NewsDetailed';
import './News.scss';
import { get } from '../adapters/xhr';

const News = () => {
  const { t } = useTranslation();

  const [news, setNews] = useState([]);
  const [currentNews, setCurrentNews] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [selectedPage, setSelectedPage] = useState(0);

  const getAllNews = async () => {
    const { data } = await get('/assets/cache/news.json');
    setPageCount(Math.ceil(data.length / 3));

    const resultFinal = data.map((item) => ({
      ...item,
      date: new Date(item.date).toUTCString(),
    }));
    setNews(resultFinal);
  };

  const getCurrentPageNews = () => {
    setCurrentNews(news.slice(selectedPage * 3, selectedPage * 3 + 3));
  };

  useEffect(async () => { await getAllNews(); }, []);
  useEffect(() => { getCurrentPageNews(); }, [news, selectedPage]);

  const handlePageClick = (data) => {
    setSelectedPage(data.selected);
  };

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <Container>
        <div className="main-panel-title text-center text-capitalize py-4 mb-3">
          {t('news')}
        </div>
        <Row className="main-panel-content text-center d-flex justify-content-center">
          <Col>
            <div>
              {currentNews.map((item) => <NewsDetailed data={item} key={item.id} />)}
            </div>
            <div id="react-paginate" className="d-flex justify-content-center">
              <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={(data) => { handlePageClick(data); }}
                containerClassName={'pagination'}
                activeClassName={'page-active'}
                pageClassName={'page'}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </motion.div>
  );
};

export default News;
