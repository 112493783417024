import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';

import './MiniIcons.scss';

const MiniIcons = () => {
  const { t, i18n } = useTranslation();

  const changeLanguage = async (newLang) => {
    await i18n.changeLanguage(newLang);
    toast(t('language-changed'));
  };

  return (
    <div className="languages ">
      {['en-US', 'de-DE', 'tr-TR']
        .map((lang, index) => <a key={index} onClick={async () => { await changeLanguage(lang); }} >
          <Image className="pe-2 lang-section" src={`${process.env.REACT_APP_API_SERVER_URL}/assets/images/flags/${lang}.png`} alt={lang} />
        </a>)}
    </div>
  );
};

export default MiniIcons;
