import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Alert,
  Col, Container, Row,
} from 'react-bootstrap';
import { motion } from 'framer-motion';

const Donate = () => {
  const { t } = useTranslation();

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <Container className="py-3">
        <Row className="mt-4 text-center">
          <Col>
            <Alert variant="warning">
              <Alert.Heading>{t('refunds')}</Alert.Heading>
              <p>
                {t('refunds-text1')}
              </p>
              <p>
                {t('refunds-text2')}
              </p>
              <p>
                {t('refunds-text3')}
              </p>
              <p>
                {t('refunds-text4')}
              </p>
              <p>
                {t('refunds-text5')}
              </p>
              <hr />
              <blockquote className="blockquote">
                <footer className="blockquote-footer text-right">{t('king-regards')} <cite title="Source Title">{t('your-team')}</cite>
                </footer>
              </blockquote>
            </Alert>
          </Col>
        </Row>
      </Container>
    </motion.div>
  );
};

export default Donate;
