import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Col, Container, ListGroup, Modal, Row,
} from 'react-bootstrap';
import { motion } from 'framer-motion';
import UserVoteFirst from './UserVoteFirst';

const Vote = () => {
  const { t } = useTranslation();
  const [modalTitle, setModalTitle] = useState('');
  const [modalComponent, setModalComponent] = useState(null);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (newModalTitle, component) => {
    setModalComponent(component);
    setModalTitle(newModalTitle);
    setShow(true);
  };

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <Container className="py-3">
        <div className="main-panel-title text-center text-capitalize py-4 mb-3">
          {t('vote4buff')}
        </div>
        <Row className="mb-3">
          <Col>
            <div>
              <Modal
                  show={show}
                  onHide={handleClose}
                  backdrop="static"
                  keyboard={false}
              >
                <div className='default-panel'>
                  <Modal.Header className='main-panel-title' closeButton closeVariant={'white'}>
                    <Modal.Title>{modalTitle}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    {modalComponent}
                  </Modal.Body>
                  <Modal.Footer>
                    <span className='text-color-gold'>Protected by reCAPTCHA.</span>
                  </Modal.Footer>
                </div>
              </Modal>
              <ListGroup className='mx-3'>
                <ListGroup.Item className='list-group-dark'>
                  <div className='d-flex justify-content-between'>
                    <div className='d-flex flex-column'>
                      <div className='fw-bold'>
                        {t('vote4buff1')}
                      </div>
                      <div className="text-color-gold">
                        {t('vote4buff-text1')}
                      </div>
                    </div>
                    <div className='align-self-center cursor-pointer-hover' onClick={() => {
                      handleShow(t('vote4buff'), <UserVoteFirst id={1}/>);
                    }}>
                      {t('vote')}
                    </div>
                  </div>
                </ListGroup.Item>
                <hr/>
                <ListGroup.Item className='list-group-dark'>
                  <div className='d-flex justify-content-between'>
                    <div className='d-flex flex-column'>
                      <div className='fw-bold text-capitalize'>
                        {t('vote4buff2')}
                      </div>
                      <div className="text-color-gold">
                        {t('vote4buff-text2')}
                      </div>
                    </div>
                    <div className='align-self-center cursor-pointer-hover' onClick={() => {
                      handleShow(t('vote4buff'), <UserVoteFirst id={2}/>);
                    }}>
                      {t('vote')}
                    </div>
                  </div>
                </ListGroup.Item>
                <hr/>
                <ListGroup.Item className='list-group-dark'>
                  <div className='d-flex justify-content-between'>
                    <div className='d-flex flex-column'>
                      <div className='fw-bold text-capitalize'>
                        {t('vote4buff3')}
                      </div>
                      <div className="text-color-gold">
                        {t('vote4buff-text3')}
                      </div>
                    </div>
                    <div className='align-self-center cursor-pointer-hover' onClick={() => {
                      handleShow(t('vote4buff'), <UserVoteFirst id={3}/>);
                    }}>
                      {t('vote')}
                    </div>
                  </div>
                </ListGroup.Item>
              </ListGroup>
            </div>
          </Col>
        </Row>
      </Container>
    </motion.div>
  );
};

export default Vote;
