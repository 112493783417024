import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';
import { Button, Image } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import WarriorImage from '../assets/images/job/warrior.jpg';
import NinjaImage from '../assets/images/job/ninja.jpg';
import SuraImage from '../assets/images/job/sura.jpg';
import ShamanImage from '../assets/images/job/shaman.jpg';
import { get, post } from '../adapters/xhr';

const UserProfileCharacters = () => {
  const { t } = useTranslation();

  const [characters, setCharacters] = useState([]);

  const getCharacters = async () => {
    const { data } = await get('/api/user/account/characters');

    const newData = data.map((item) => ({
      ...item,
      job: (item.job === 0 || item.job === 4 ? <Image className="character-img" src={WarriorImage} alt="" /> : item.job === 1 || item.job === 5
        ? <Image className="character-img" src={NinjaImage} alt="" /> : item.job === 2 || item.job === 6 ? <Image className="character-img" src={SuraImage} alt="" />
          : <Image className="character-img" src={ShamanImage} alt="" />),
    }));

    setCharacters(newData);
  };

  const debugCharacter = async (id) => {
    try {
      await post('/api/user/character/debug', {
        id,
      });

      toast.success(t('debug-character-success'));
    } catch (e) {
      if (e.response.status === 400) {
        toast.error(t('debug-character-delay'));
      } else {
        toast.error(t('debug-character-error'));
      }
    }
  };

  useEffect(async () => { await getCharacters(); }, []);

  const columns = [
    { dataField: 'job', text: t('job') },
    { dataField: 'name', text: t('name') },
    { dataField: 'level', text: t('level') },
    { dataField: 'exp', text: t('exp') },
    { dataField: 'gold', text: 'Yang' },
    {
      dataField: 'debug',
      text: t('debug'),
      isDummyField: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
          <h5>
            <Button size="sm" className="default-button" onClick={ async () => { await debugCharacter(row.id); }}>
              {t('debug')}
            </Button>
          </h5>
      ),
    },
  ];

  return (
      <BootstrapTable noDataIndication={() => t('no-data-to-display')}
                      bordered={false}
                      bootstrap4={true}
                      classes='table-data text-center'
                      headerClasses='color-gold'
                      bodyClasses='border-0'
                      keyField='id'
                      data={characters}
                      columns={columns}
      />
  );
};

export default UserProfileCharacters;
