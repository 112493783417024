import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import AuthProvider from '../contexts/AuthProvider';

const AuthenticatedRoute = ({ children }) => {
  const { account } = useContext(AuthProvider.context);
  const { auth, user } = account;

  return auth && user.admin > 0 ? children : <Navigate to="/" />;
};

export default AuthenticatedRoute;
