import React from 'react';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import {
  Button, Col, Container, Form, Row,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { post } from '../adapters/xhr';

const initialUser = {
  email: '',
};

const ResetUsername = () => {
  const { t } = useTranslation();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const performUsernameRecovery = async (values, token) => {
    const { email } = values;

    await post('/api/user/account/recovery/username', {
      email,
      token,
    });
  };

  const initUsernameRecovery = async (values) => {
    if (!executeRecaptcha) {
      return;
    }

    const token = await executeRecaptcha('reset_username');
    await performUsernameRecovery(values, token);
  };

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email(t('email-format'))
      .required(t('field-required')),
  });

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <Container>
        <div className="main-panel-title text-center text-capitalize py-4 mb-3">
          {t('reset-username')}
        </div>
        <Row>
          <Col className="d-flex justify-content-center">
            <Formik
              initialValues={initialUser}
              validationSchema={validationSchema}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                setSubmitting(true);
                await toast.promise(
                  initUsernameRecovery(values),
                  {
                    loading: t('loading'),
                    success: t('reset-process-success'),
                    error: t('process-error'),
                  },
                );

                resetForm();
                setSubmitting(false);
              }}
            >
              {({
                touched,
                errors,
                isSubmitting,
                handleSubmit,
                handleChange,
              }) => (
            <Form className="py-2 text-center" onSubmit={handleSubmit}>
              <Form.Group controlId="email" className='py-3'>
                <Form.Label>{t('email-address')}</Form.Label>
                <Form.Control
                  className={touched.email && errors.email ? 'dark-form-control error' : 'dark-form-control'}
                  size="sm"
                  type="email"
                  placeholder={t('email-address')}
                  onChange={handleChange}
                />
                {(touched.email && errors.email) && <div className="error-message">{errors.email}</div>}
              </Form.Group>

              <Button type="submit" size="sm" className="default-button" disabled={isSubmitting}>
                {t('submit')}
              </Button>
            </Form>
              )}
            </Formik>
          </Col>
        </Row>
        <Row className="text-center my-3">
          <Col>
            <small><FontAwesomeIcon icon={faArrowCircleLeft}/> <Link className="text-link" to="/login">{t('back-to-login')}</Link></small>
          </Col>
        </Row>
      </Container>
    </motion.div>
  );
};

export default ResetUsername;
