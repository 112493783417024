import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import {
  Button, Col, Container, Form, Row,
} from 'react-bootstrap';
import * as yup from 'yup';
import { Formik } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowCircleRight, faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

import { post } from '../adapters/xhr';
import AuthProvider from '../contexts/AuthProvider';

const initialUser = {
  username: '',
  password: '',
};

const Login = () => {
  const { t } = useTranslation();
  const { setAccount } = useContext(AuthProvider.context);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const performLogin = async (values, token) => {
    const { username, password } = values;

    const result = await post('/api/auth/signin', {
      username,
      password,
      token,
    });

    if (result.status === 200) {
      setAccount(result.data);
    }
  };

  const initLogin = async (values) => {
    if (!executeRecaptcha) {
      return;
    }

    const token = await executeRecaptcha('login');
    if (token) {
      await performLogin(values, token);
    }
  };

  const validationSchema = yup.object().shape({
    username: yup
      .string()
      .matches(/^[A-Za-z\d]{2,16}$/, t('account-match'))
      .required(t('field-required')),
    password: yup
      .string()
      .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,16}$/, t('password-match'))
      .min(8, t('password-min'))
      .max(16, t('password-max'))
      .required(t('field-required')),
  });

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <Container>
        <div className="main-panel-title text-center text-capitalize py-4 mb-3">
          {t('login')}
        </div>
        <Row className="d-flex justify-content-around">
          <Col lg={4} className="text-center">
            <Formik
              initialValues={initialUser}
              validationSchema={validationSchema}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                setSubmitting(true);
                await toast.promise(
                  initLogin(values),
                  {
                    loading: t('loading'),
                    success: t('login-success'),
                    error: t('login-bad-credentials'),
                  },
                );

                resetForm();
                setSubmitting(false);
              }}
            >
              {({
                touched,
                errors,
                isSubmitting,
                handleSubmit,
                handleChange,
              }) => (
                <Form className="py-2 text-center" onSubmit={handleSubmit}>
                  <Form.Group controlId="username">
                    <Form.Label size="sm">{t('user-name')}</Form.Label>
                    <Form.Control
                      className={touched.username && errors.username ? 'dark-form-control error' : 'dark-form-control'}
                      size="sm"
                      placeholder={t('user-name')}
                      onChange={handleChange}
                    />
                    {(touched.username && errors.username) && <div className="error-message">{errors.username}</div>}
                  </Form.Group>
                  <Form.Group controlId="password" className='py-3'>
                    <Form.Label size="sm">{t('password')}</Form.Label>
                    <Form.Control
                      className={touched.password && errors.password ? 'dark-form-control error' : 'dark-form-control'}
                      size="sm"
                      type="password"
                      placeholder={t('password')}
                      onChange={handleChange}
                    />
                    {(touched.password && errors.password) && <div className="error-message">{errors.password}</div>}
                  </Form.Group>
                  <Button type="submit" size="sm" className="default-button text-capitalize" disabled={isSubmitting}>
                    {t('login')}
                  </Button>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
        <Row className="text-center mt-3">
          <Col>
            <small><FontAwesomeIcon icon={faArrowCircleRight}/> <Link className="text-link" to="/register">{t('go-to-register-extended')}</Link></small>
          </Col>
        </Row>
        <Row className="text-center mt-3">
          <Col>
            <small><FontAwesomeIcon icon={faQuestionCircle}/> <Link className="text-link" to="/recovery/password">{t('forgot-password')}</Link></small>
          </Col>
        </Row>
        <Row className="text-center mb-3">
          <Col>
            <small><FontAwesomeIcon icon={faQuestionCircle}/> <Link className="text-link" to="/recovery/username">{t('forgot-username')}</Link></small>
          </Col>
        </Row>
      </Container>
    </motion.div>
  );
};

export default Login;
