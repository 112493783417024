import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';
import {
  Alert, Button, Col, Form, InputGroup, Row,
} from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { post } from '../adapters/xhr';

const initialUser = {
  password: '',
  email: '',
  emailVerify: '',
};

const UserChangePassword = () => {
  const { t } = useTranslation();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const [visiblePassword, setVisiblePassword] = useState(false);

  const performChangeEmail = async (values, token) => {
    const { password, email } = values;

    await post('/api/user/account/email/begin', {
      password,
      newEmail: email,
      token,
    });
  };

  const initChangeEmail = async (values) => {
    if (!executeRecaptcha) {
      return;
    }

    const token = await executeRecaptcha('login');
    await performChangeEmail(values, token);
  };

  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,16}$/, t('password-match'))
      .min(8, t('password-min'))
      .max(16, t('password-max'))
      .required(t('field-required')),
    email: yup
      .string()
      .email(t('email-format'))
      .required(t('field-required')),
    emailVerify: yup
      .string()
      .email(t('email-format'))
      .oneOf(
        [yup.ref('email'), null],
        t('emails-not-match'),
      )
      .required(t('field-required')),
  });

  return (
    <>
    <Row className="mt-4 text-center">
      <Col>
        <Alert variant="warning">
          <Alert.Heading>{t('reminder')}</Alert.Heading>
          <p>
            {t('email-will-arrive')}
          </p>
          <hr />
          <blockquote className="blockquote">
            <footer className="blockquote-footer text-right">{t('king-regards')} <cite title="Source Title">{t('your-team')}</cite>
            </footer>
          </blockquote>
        </Alert>
      </Col>
    </Row>
    <Row className="mt-4">
      <Col className="d-flex justify-content-center">
        <Formik
          initialValues={initialUser}
          validationSchema={validationSchema}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            await toast.promise(
              initChangeEmail(values),
              {
                loading: t('loading'),
                success: t('reset-process-success'),
                error: t('process-error'),
              },
            );

            resetForm();
            setSubmitting(false);
          }}
        >
          {({
            touched,
            errors,
            isSubmitting,
            handleSubmit,
            handleChange,
          }) => (
        <Form className="py-2 text-center" onSubmit={handleSubmit}>
          <Form.Group controlId="password" className='py-3'>
            <Form.Label size="sm">{t('password')}</Form.Label>
            <InputGroup>
              <Form.Control
                className={touched.password && errors.password ? 'dark-form-control error' : 'dark-form-control'}
                size="sm"
                type={visiblePassword ? 'text' : 'password'}
                placeholder={t('password')}
                onChange={handleChange}
              />
              <InputGroup.Text className='form-faicon'>
                <FontAwesomeIcon
                  icon={visiblePassword ? faEye : faEyeSlash}
                  onClick={() => { setVisiblePassword(!visiblePassword); }}
                />
              </InputGroup.Text>
            </InputGroup>
            {(touched.password && errors.password) && <div className="error-message">{errors.password}</div>}
          </Form.Group>

          <Form.Group controlId="email" className='py-3'>
            <Form.Label>{t('new-email')}</Form.Label>
            <Form.Control
              className={touched.email && errors.email ? 'dark-form-control error' : 'dark-form-control'}
              size="sm"
              type="email"
              placeholder={t('new-email')}
              onChange={handleChange}
            />
            {(touched.email && errors.email) && <div className="error-message">{errors.email}</div>}
          </Form.Group>

          <Form.Group controlId="emailVerify" className='py-3'>
            <Form.Label>{t('new-email-verify')}</Form.Label>
            <Form.Control
              className={touched.emailVerify && errors.emailVerify ? 'dark-form-control error' : 'dark-form-control'}
              size="sm"
              type="email"
              placeholder={t('new-email-verify')}
              onChange={handleChange}
            />
            {(touched.emailVerify && errors.emailVerify) && <div className="error-message">{errors.emailVerify}</div>}
          </Form.Group>

          <Button type="submit" size="sm" className="default-button" disabled={isSubmitting}>
            {t('change-email')}
          </Button>
        </Form>
          )}
        </Formik>
      </Col>
    </Row>
      </>
  );
};

export default UserChangePassword;
