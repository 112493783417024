import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import {
  Alert, Button, Col, Container, Form, Row,
} from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { patch, post } from '../adapters/xhr';

const initialUser = {
  password: '',
  passwordVerify: '',
};

const ResetPasswordConfirm = () => {
  const { t } = useTranslation();

  const { username, uniqueKey } = useParams();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [validationStatus, setValidationStatus] = useState(0);

  const performPasswordChange = async (values, token) => {
    const { password } = values;

    await patch('/api/user/account/recovery/password', {
      password,
      token,
    });
  };

  const initPasswordChange = async (values) => {
    if (!executeRecaptcha) {
      return;
    }

    const token = await executeRecaptcha('reset_password_change');
    await performPasswordChange(values, token);
  };

  // Check if unique key is valid for the account reset process
  useEffect(async () => {
    try {
      await post('/api/user/account/recovery/password/validate', {
        username,
        uniqueKey,
      });

      setValidationStatus(1);
    } catch (e) {
      setValidationStatus(-1);
    }
  }, []);

  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,16}$/, t('password-match'))
      .min(8, t('password-min'))
      .max(16, t('password-max'))
      .required(t('field-required')),
    passwordVerify: yup
      .string()
      .oneOf(
        [yup.ref('password'), null],
        t('passwords-not-match'),
      )
      .required(t('field-required')),
  });

  switch (validationStatus) {
    case 1: return (
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
        <Container>
          <div className="main-panel-title text-center text-capitalize py-4 mb-3">
            {t('reset-password')}
          </div>
          <Row>
            <Col>
              <Alert variant="success">
                The provided username and secret key combination is correct.
                Now please enter carefully a new password and you&lsquo;re good to go!
              </Alert>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-center">
              <Formik
                initialValues={initialUser}
                validationSchema={validationSchema}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  setSubmitting(true);
                  await toast.promise(
                    initPasswordChange(values),
                    {
                      loading: t('loading'),
                      success: t('reset-process-success'),
                      error: t('process-error'),
                    },
                  );

                  resetForm();
                  setSubmitting(false);
                }}
              >
                {({
                  touched,
                  errors,
                  isSubmitting,
                  handleSubmit,
                  handleChange,
                }) => (
              <Form className="py-2 text-center" onSubmit={handleSubmit}>
                <Form.Group controlId="password" className='py-3'>
                  <Form.Label size="sm">{t('password')}</Form.Label>
                  <Form.Control
                    className={touched.password && errors.password ? 'dark-form-control error' : 'dark-form-control'}
                    size="sm"
                    type="password"
                    placeholder={t('password')}
                    onChange={handleChange}
                  />
                  {(touched.password && errors.password) && <div className="error-message">{errors.password}</div>}
                </Form.Group>

                <Form.Group controlId="passwordVerify" className='py-3'>
                  <Form.Label>{t('password-verify')}</Form.Label>
                  <Form.Control
                    className={touched.passwordVerify && errors.passwordVerify ? 'dark-form-control error' : 'dark-form-control'}
                    size="sm" type="password"
                    placeholder={t('password-verify')}
                    onChange={handleChange}
                  />
                  {(touched.passwordVerify && errors.passwordVerify) && <div className="error-message">{errors.passwordVerify}</div>}
                </Form.Group>

                <Button type="submit" size="sm" className="default-button" disabled={isSubmitting}>
                  {t('submit')}
                </Button>
              </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </Container>
      </motion.div>
    );
    case -1: return (
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
        <Container>
          <Row className="pt-3 text-center">
            <Col>
              <h5 className="text-uppercase"><FontAwesomeIcon icon={faUserPlus}/> {t('reset-password')}</h5>
            </Col>
          </Row>
          <Row>
            <Col>
              <Alert variant="danger">
                <Alert.Heading>Aw, snap :(</Alert.Heading>
                <p>
                  The username and secret key you provided is not correct
                  or it might have already expired, having a time limit of 24h.
                </p>
                <p>
                  If that&lsquo;s the case, please try to
                  reset your password again, and it should work next time!
                </p>
                <hr />
                <p className="mb-0">
                  If you think this error is wrong,
                  please contact a staff member as soon as possible.
                </p>
              </Alert>
            </Col>
          </Row>
        </Container>
      </motion.div>
    );
    default: return <div/>;
  }
};

export default ResetPasswordConfirm;
