import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  Alert, Col, Container, Row,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { post } from '../adapters/xhr';

const RegisterConfirm = () => {
  const { t } = useTranslation();

  const { username, uniqueKey } = useParams();

  const [validationStatus, setValidationStatus] = useState(0);

  // Check if unique key is valid for the register confirmation process
  useEffect(async () => {
    try {
      await post('/api/auth/signup/validate', {
        username,
        uniqueKey,
      });

      setValidationStatus(1);
    } catch (e) {
      setValidationStatus(-1);
    }
  }, []);

  switch (validationStatus) {
    case 1: return (
      <Container>
        <Row className="pt-3 text-center">
          <Col>
            <h5 className="text-uppercase"><FontAwesomeIcon icon={faUserPlus}/> {t('change-email')}</h5>
          </Col>
        </Row>
        <Row>
          <Col>
            <Alert variant="success">
              <Alert.Heading>Success!</Alert.Heading>
              <p>
                Aww yeah, the provided username and secret key combination is correct.
              </p>
              <hr />
              <p className="mb-0">
                Your account has been successfully confirmed!
              </p>
            </Alert>
          </Col>
        </Row>
      </Container>
    );
    case -1: return (
      <Container>
        <div className="main-panel-title text-center text-capitalize py-4 mb-3">
          {t('register')}
        </div>
        <Row>
          <Col>
            <Alert variant="danger">
              <Alert.Heading>Aw, snap :(</Alert.Heading>
              <p>
                The username and secret key you provided is not correct.
              </p>
              <hr />
              <p className="mb-0">
                If you think this error is wrong, please contact a staff member as soon as possible.
              </p>
            </Alert>
          </Col>
        </Row>
      </Container>
    );
    default: return <div/>;
  }
};

export default RegisterConfirm;
