import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  Button,
  Col, Container, Image, Row, Table,
} from 'react-bootstrap';
import './RankingSide.scss';
import { get } from '../adapters/xhr';
import FirstIcon from '../assets/images/1.png';
import SecondLogo from '../assets/images/2.png';
import ThirdLogo from '../assets/images/3.png';

const RankingSideGuilds = () => {
  const { t } = useTranslation();

  const [data, setData] = useState([]);

  const getRanking = async () => {
    const result = await get('/assets/cache/guilds.json');
    setData(result.data.slice(0, 10));
  };

  useEffect(async () => { await getRanking(); }, []);

  return (
    <Container>
      <div className="main-panel-title ps-3 py-2 opacity-75">
        {t('ranking-guilds')}
      </div>
      <Row>
        <Col className="text-center py-3">
          <Table size="sm" borderless>
            <tbody className="text-color-gold">
            {data.map((entry, index) => (
              <tr key={index} style={{ borderTop: '0' }} className="pb-5">
                <td className="text-secondary-custom font-weight-light">
                  {index === 0 ? <Image src={FirstIcon} />
                    : index === 1 ? <Image src={SecondLogo} />
                      : index === 2 ? <Image src={ThirdLogo} />
                        : index + 1 }
                </td>
                <td className="text-primary-gold-custom text-left font-weight-light">
                  {index < 3 ? <span className='text-white'>{entry.name}</span> : entry.name }
                </td>
                <td className='text-capitalize'>
                  {(entry.empire === 3 ? <span style={{ color: '#3f99c9' }}>{t('blue')}</span>
                    : entry.empire === 2 ? <span style={{ color: '#cbca65' }}>{t('yellow')}</span>
                      : <span style={{ color: '#d13348' }}>{t('red')}</span>)
                  }
                </td>
                <td className="text-secondary-custom font-weight-light">
                  {index < 3 ? <span className='text-white'>{entry.level}</span> : entry.level }
                </td>
              </tr>))}
            </tbody>
          </Table>
          <div className='py-2'>
            <Link to="/ranking">
              <Button size="sm" className="default-button text-capitalize">
                {t('full-ranking')}
              </Button>
            </Link>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default RankingSideGuilds;
