import React from 'react';
import { motion } from 'framer-motion';

const NotFound = () => (
  <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
    <div className="text-center my-5">
      <p>
        <h1>Oops!</h1>
      </p>
      <p>
        The requested URL could not be found.
      </p>
    </div>
  </motion.div>
);

export default NotFound;
