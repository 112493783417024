import React from 'react';
import {
  Col, Container, Row,
} from 'react-bootstrap';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const PresentationDE = () => {
  const { i18n } = useTranslation(); // Include i18n to get the current language

  // List of indices corresponding to .gif files
  const gifIndices = [8, 14, 17, 19, 21, 25, 27, 29, 31, 47, 68, 69, 70, 72];

  // Predefined array of image paths
  const imagePaths = Array.from({ length: 90 }, (_, i) => {
    const suffix = (i + 1).toString().padStart(2, '0');
    const format = gifIndices.includes(i + 1) ? 'gif' : 'jpg'; // Check if index corresponds to a gif
    return `Vorstellung_${suffix}.${format}`;
  });

  return (
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
          <Container className="py-3">
              <Row className="mb-3">
                  <Col>
                      {imagePaths.map((src, index) => (
                          <img key={index} src={`https://cdn.unitedmt2.com/presentation/${i18n.language}/${src}`} style={{ width: '100%', display: 'block' }} alt={`Presentation ${index + 1}`} />
                      ))}
                  </Col>
              </Row>
          </Container>
      </motion.div>
  );
};

export default PresentationDE;
