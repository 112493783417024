import React, { Suspense } from 'react';
import { BrowserRouter, Link } from 'react-router-dom';
import toast, { ToastBar, Toaster } from 'react-hot-toast';
import { QueryClient, QueryClientProvider } from 'react-query';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import {
  Container, Row, Col, Image,
} from 'react-bootstrap';
import AuthProvider from './contexts/AuthProvider';
import Header from './components/Header';
import Main from './components/Main';
import Footer from './components/Footer';
import Loading from './components/Loading';
import './App.scss';
import Logo from './assets/images/logo.png';
import PlayNow from './assets/images/play_now_button.png';
import './assets/res/fonts.css';

const queryClient = new QueryClient();

const App = () => (
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <GoogleReCaptchaProvider useRecaptchaNet
           reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}
           scriptProps={{
             async: true, // optional, default to false,
             defer: true, // optional, default to false
             appendTo: 'body', // optional, default to "head", can be "head" or "body",
             dataTheme: 'dark',
           }}
          >
            <Suspense fallback={<Loading />}>
              <AuthProvider>
                <Header />
                <Container className='header-padding'>
                  <Row>
                    <Col>
                      <div className='float-end'>
                        <div className='d-flex flex-column'>
                          <Image src={Logo} alt='' height={315} width={646} />
                            <Link to="/download" className="align-self-center">
                                <Image className='align-self-center lang-section' src={PlayNow} alt='' height={61} width={256} />
                            </Link>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
                <Main />
                <Footer />
                <Toaster
                  position="bottom-right"
                  toastOptions={{
                    duration: 5000,
                    style: {
                      background: 'royalblue',
                      color: 'white',
                    },
                    success: {
                      style: {
                        background: 'green',
                        color: 'white',
                      },
                    },
                    error: {
                      style: {
                        background: 'darkred',
                        color: 'white',
                      },
                    },
                  }}
                >
                  {(t) => (
                    <ToastBar toast={t}>
                      {({ icon, message }) => (
                        <>
                          {icon}
                          {message}
                          {t.type !== 'loading' && (
                            <button
                              style={{ border: '0', backgroundColor: 'transparent', color: 'white' }}
                              onClick={() => toast.dismiss(t.id)}>&#x2715;</button>
                          )}
                        </>
                      )}
                    </ToastBar>
                  )}
                </Toaster>
              </AuthProvider>
            </Suspense>
          </GoogleReCaptchaProvider>
        </BrowserRouter>
      </QueryClientProvider>
);

export default App;
