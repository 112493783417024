import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import {
  Alert, Button, Col, Container, Form, Row,
} from 'react-bootstrap';
import { post } from '../adapters/xhr';

const UserResetSafeboxPassword = () => {
  const { t } = useTranslation();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const performSafeboxRecovery = async () => {
    try {
      if (!executeRecaptcha) {
        return;
      }

      const token = await executeRecaptcha('safebox_reset');

      await post('/api/user/account/recovery/safebox', {
        token,
      });

      toast.success(t('reset-process-success'));
    } catch (e) {
      if (e.response.status === 400) {
        toast.error(t('reset-process-delay-1h'));
      } else {
        toast.error(t('process-error'));
      }
    }
  };

  return (
    <Container className="mt-4 text-center">
      <Row>
        <Col>
          <Alert variant="warning">
            {t('email-after-submit')}
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col className="text-center">
          <Form className="py-2 text-center">
            <Button size="sm" className="default-button" onClick={async () => {
              await performSafeboxRecovery();
            }}>
              {t('submit')}
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default UserResetSafeboxPassword;
