import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ListGroup, Modal,
} from 'react-bootstrap';

import UserChangePassword from './UserChangePassword';
import UserChangeEmail from './UserChangeEmail';
import UserChangeSafeboxPassword from './UserChangeSafeboxPassword';
import UserChangeCharDelPassword from './UserChangeCharDelPassword';
import UserResetSafeboxPassword from './UserResetSafeboxPassword';
import UserResetSocialid from './UserResetSocialid';

const UserProfileSettings = () => {
  const { t } = useTranslation();

  const [modalTitle, setModalTitle] = useState('');
  const [modalComponent, setModalComponent] = useState(null);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (newModalTitle, component) => {
    setModalComponent(component);
    setModalTitle(newModalTitle);
    setShow(true);
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <div className='default-panel'>
          <Modal.Header className='main-panel-title' closeButton closeVariant={'white'}>
            <Modal.Title>{modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {modalComponent}
          </Modal.Body>
          <Modal.Footer>
            <span className='text-color-gold'>Protected by reCAPTCHA.</span>
          </Modal.Footer>
        </div>
      </Modal>
      <ListGroup className='mx-3'>
        <ListGroup.Item className='list-group-dark'>
          <div className='d-flex justify-content-between'>
            <div className='d-flex flex-column'>
              <div className='fw-bold'>
                {t('password')}
              </div>
              <div className="text-color-gold">
                {t('create-or-update-password')}
              </div>
            </div>
            <div className='align-self-center cursor-pointer-hover' onClick={() => { handleShow(t('password'), <UserChangePassword />); }}>
              {t('update')}
            </div>
          </div>
        </ListGroup.Item>
        {/* <hr />
        <ListGroup.Item className='list-group-dark'>
          <div className='d-flex justify-content-between'>
            <div className='d-flex flex-column'>
              <div className='fw-bold'>
                {t('2-step-verification')} <Badge bg="success">New</Badge>
              </div>
              <div className="text-color-gold">
                {t('2-step-verification-detailed')}
              </div>
            </div>
            <div className='align-self-center cursor-pointer-hover'>
              {t('set-up')}
            </div>
          </div>
        </ListGroup.Item> */}
        <hr />
        <ListGroup.Item className='list-group-dark'>
          <div className='d-flex justify-content-between'>
            <div className='d-flex flex-column'>
              <div className='fw-bold text-capitalize'>
                {t('email')}
              </div>
              <div className="text-color-gold">
                {t('change-email')}
              </div>
            </div>
            <div className='align-self-center cursor-pointer-hover' onClick={() => { handleShow(t('email'), <UserChangeEmail />); }}>
              {t('update')}
            </div>
          </div>
        </ListGroup.Item>
        <hr />
        <ListGroup.Item className='list-group-dark'>
          <div className='d-flex justify-content-between'>
            <div className='d-flex flex-column'>
              <div className='fw-bold'>
                {t('safebox-password')}
              </div>
              <div className="text-color-gold">
                {t('change-safebox-password')}
              </div>
            </div>
            <div className='align-self-center cursor-pointer-hover' onClick={() => { handleShow(t('safebox-password'), <UserChangeSafeboxPassword />); }}>
              {t('update')}
            </div>
          </div>
        </ListGroup.Item>
        <hr />
        <ListGroup.Item className='list-group-dark'>
          <div className='d-flex justify-content-between'>
            <div className='d-flex flex-column'>
              <div className='fw-bold'>
                {t('safebox-password')}
              </div>
              <div className="text-color-gold">
                {t('send-safebox-password')}
              </div>
            </div>
            <div className='align-self-center cursor-pointer-hover' onClick={() => { handleShow(t('safebox-password'), <UserResetSafeboxPassword />); }}>
              {t('recover')}
            </div>
          </div>
        </ListGroup.Item>
        <hr />
        <ListGroup.Item className='list-group-dark'>
          <div className='d-flex justify-content-between'>
            <div className='d-flex flex-column'>
              <div className='fw-bold'>
                {t('character-del-password')}
              </div>
              <div className="text-color-gold">
                {t('change-character-del-password')}
              </div>
            </div>
            <div className='align-self-center cursor-pointer-hover' onClick={() => { handleShow(t('character-del-password'), <UserChangeCharDelPassword />); }}>
              {t('update')}
            </div>
          </div>
        </ListGroup.Item>
        <hr />
        <ListGroup.Item className='list-group-dark'>
          <div className='d-flex justify-content-between'>
            <div className='d-flex flex-column'>
              <div className='fw-bold'>
                {t('character-del-password')}
              </div>
              <div className="text-color-gold">
                {t('send-character-del-password')}
              </div>
            </div>
            <div className='align-self-center cursor-pointer-hover' onClick={() => { handleShow(t('character-del-password'), <UserResetSocialid />); }}>
              {t('recover')}
            </div>
          </div>
        </ListGroup.Item>
        <hr />
      </ListGroup>
    </div>
  );
};

export default UserProfileSettings;
