import React from 'react';
import { Row, Col, Spinner } from 'react-bootstrap';

const Loading = () => <Row>
    <Col className="text-center my-5">
      <Spinner animation="grow" />
    </Col>
  </Row>;

export default Loading;
