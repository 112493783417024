import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button, Col, Container, Row,
} from 'react-bootstrap';
import { motion } from 'framer-motion';

import RankingPlayers from './RankingPlayers';
import RankingGuilds from './RankingGuilds';

const Ranking = () => {
  const { t } = useTranslation();

  const [selectedPage, setSelectedPage] = useState(0);

  const renderSelectedPage = () => {
    switch (selectedPage) {
      case 0: return <RankingPlayers />;
      case 1: return <RankingGuilds />;
      default: return <div />;
    }
  };

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <Container>
        <div className="main-panel-title text-center text-capitalize py-4 mb-3">
          {t('ranking')}
        </div>
        <Row className="mb-3">
          <Col className="text-center">
            <Button className="default-button" onClick={() => { setSelectedPage(0); }}>
              {t('players')}
            </Button>
          </Col>
          <Col className="text-center">
            <Button size="md" className="default-button" onClick={() => { setSelectedPage(1); }}>
              {t('guilds')}
            </Button>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            { renderSelectedPage() }
          </Col>
        </Row>
      </Container>
    </motion.div>
  );
};

export default Ranking;
