import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Col, Container, Nav, Row,
} from 'react-bootstrap';
import { motion } from 'framer-motion';

import UserProfileInfo from './UserProfileInfo';
import UserProfileSettings from './UserProfileSettings';
import UserProfileCharacters from './UserProfileCharacters';

const UserProfile = () => {
  const { t } = useTranslation();

  const [selectedNav, setSelectedNav] = useState('profile');

  const renderSelectedNav = () => {
    switch (selectedNav) {
      case 'characters': return <UserProfileCharacters />;
      case 'settings': return <UserProfileSettings />;
      default: return <UserProfileInfo />;
    }
  };

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <Container className="py-3">
        <div className="main-panel-title text-center text-capitalize py-4 mb-3">
          {t('user-panel')}
        </div>
        <Row className="mb-3">
          <Col>
            <Nav fill defaultActiveKey="profile">
              <Nav.Item className="choose-settings-navbar-item">
                <Nav.Link eventKey="profile" onClick={() => { setSelectedNav('profile'); }}>{t('user-profile')}</Nav.Link>
              </Nav.Item>
              <Nav.Item className="choose-settings-navbar-item">
                <Nav.Link eventKey="characters" onClick={() => { setSelectedNav('characters'); }}>{t('characters')}</Nav.Link>
              </Nav.Item>
              <Nav.Item className="choose-settings-navbar-item">
                <Nav.Link eventKey="settings" onClick={() => { setSelectedNav('settings'); }}>{t('security')}</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
        </Row>
        <Row className='d-flex justify-content-center'>
          <Col>
            { renderSelectedNav() }
          </Col>
        </Row>
      </Container>
    </motion.div>
  );
};

export default UserProfile;
