import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import {
  Button, Col, Row,
} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { get } from '../adapters/xhr';

const RankingGuilds = () => {
  const { t } = useTranslation();

  const [guilds, setGuilds] = useState([]);
  const [filteredGuilds, setFilteredGuilds] = useState([]);
  const [searchName, setSearchName] = useState('');

  const handleSearch = () => {
    setFilteredGuilds(guilds
      .filter((item) => item.name.toLowerCase().includes(searchName.toLowerCase())));
  };

  const getData = async () => {
    const { data } = await get('/assets/cache/guilds.json');

    const newData = data.map((item, index) => ({
      ...item,
      empire: item.empire === 3 ? <span style={{ color: '#3f99c9' }}>{t('blue')}</span>
        : item.empire === 2 ? <span style={{ color: '#cbca65' }}>{t('yellow')}</span>
          : <span style={{ color: '#d13348' }}>{t('red')}</span>,
      rank: `${index + 1}.`,
      key: index,
    }));

    setGuilds(newData);
    setFilteredGuilds(newData.filter((item) => item.name.toLowerCase()
      .includes(searchName.toLowerCase())));
  };

  useEffect(async () => { await getData(); }, []);
  useEffect(() => { handleSearch(); }, [searchName]);

  const columns = [
    { dataField: 'rank', text: t('rank') },
    { dataField: 'name', text: t('name') },
    { dataField: 'level', text: t('level') },
    { dataField: 'empire', text: t('empire') },
    { dataField: 'master', text: t('guild-master') },
  ];

  return (
    <>
      <Row>
        <Col>
          <div className="input-group d-flex justify-content-around">
            <div className="input-group w-50">
              <input type="text" className="form-control dark-form-control" placeholder={t('search-by-guildname')} onChange={(e) => { setSearchName(e.target.value); }} />
              <Button variant="warning" onClick={() => {
                toast(t('start-typing-search'));
              }}>
                <FontAwesomeIcon icon={faSearch} />
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="text-center">
          <BootstrapTable noDataIndication={() => t('no-data-to-display')}
            bordered={false}
            bootstrap4={true}
            classes='table-data'
            headerClasses='color-gold'
            bodyClasses='border-0'
            keyField='key'
            data={filteredGuilds}
            columns={columns}
            pagination={paginationFactory({ prePageText: t('previous'), nextPageText: t('next'), hideSizePerPage: true })}
          />
        </Col>
      </Row>
    </>
  );
};

export default RankingGuilds;
