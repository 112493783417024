import React from 'react';
import { Link } from 'react-router-dom';
import { Image } from 'react-bootstrap';

import './InteractiveButtons.scss';
import Button1 from '../assets/images/button1.png';
import Button2 from '../assets/images/button2.png';
import Button3 from '../assets/images/button3.png';

const InteractiveButtons = () => (
    <>
      <Link to="/presentation" className="d-flex flex-column justify-content-center text-center download-section">
        <Image src={Button1} alt='' />
      </Link>
      <Link to="/user/vote" className="d-flex flex-column justify-content-center text-center download-section">
        <Image src={Button2} alt='' />
      </Link>
      <a href="https://discord.gg/kEkgm78Ehv" target="_blank" rel="noopener noreferrer"
        className="d-flex flex-column justify-content-center text-center download-section">
        <Image src={Button3} alt=''/>
      </a>
    </>
);

export default InteractiveButtons;
