import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Link } from 'react-router-dom';
import {
  Button, Col, Container, Form, FormControl, Image, InputGroup, ListGroup, Row,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRight, faEnvelope, faLock,
  faShoppingCart, faSignOutAlt, faUser, faUserShield,
} from '@fortawesome/free-solid-svg-icons';

import * as yup from 'yup';
import { Formik } from 'formik';
import AuthProvider from '../contexts/AuthProvider';
import defaultUserPanelImage from '../assets/images/default-panel.png';
import './UserPanel.scss';
import { post } from '../adapters/xhr';

const initialUser = {
  username: '',
  password: '',
};

const UserPanel = () => {
  const { t } = useTranslation();
  const { account, setAccount } = useContext(AuthProvider.context);
  const { auth, user } = account;

  const { executeRecaptcha } = useGoogleReCaptcha();

  const performLogin = async (values, token) => {
    const { username, password } = values;

    const result = await post('/api/auth/signin', {
      username,
      password,
      token,
    });

    if (result.status === 200) {
      setAccount(result.data);
    }
  };

  const initLogin = async (values) => {
    if (!executeRecaptcha) {
      return;
    }

    const token = await executeRecaptcha('login');
    if (token) {
      await performLogin(values, token);
    }
  };

  const validationSchema = yup.object().shape({
    username: yup
      .string()
      .matches(/^[A-Za-z\d]{2,16}$/, t('account-match'))
      .required(t('field-required')),
    password: yup
      .string()
      .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,16}$/, t('password-match-minimal'))
      .min(8, t('password-min'))
      .max(16, t('password-max'))
      .required(t('field-required')),
  });

  const performLogout = async () => {
    const { data } = await post('/api/auth/signout');
    setAccount(data);
    toast.success(t('logout-success'));
  };

  if (auth) {
    return (
      <Container>
        <Row className="pt-1 text-center">
          <Col>
            <div>
              <Image width="70px" src={defaultUserPanelImage} alt="profile avatar" />
            </div>
            <small>{t('welcome')} {user.username}</small>
          </Col>
        </Row>
        <Row className="text-center">
          <Col>
            <ListGroup className="user-panel-list">
              {user.admin > 0 && <ListGroup.Item as={Link} to="/admin/profile" className="user-panel-item"><FontAwesomeIcon icon={faUserShield} /> {t('admin-panel')}</ListGroup.Item>}
              <ListGroup.Item as={Link} to="/user/profile" className="user-panel-item py-3"><FontAwesomeIcon icon={faUser} /> {t('user-panel')}</ListGroup.Item>
              <ListGroup.Item as={Link} to="/user/donate" className="user-panel-item py-3"><FontAwesomeIcon icon={faShoppingCart} /> {t('donate')}</ListGroup.Item>
              <ListGroup.Item onClick={async () => { await performLogout(); }} className="user-panel-item-logout py-3"><FontAwesomeIcon icon={faSignOutAlt} /> {t('logout')}</ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container>
      <div className="main-panel-title ps-3 py-2 opacity-75">
        {t('login-box')}
      </div>
      <Row className='py-5'>
        <Col>
          <Formik
            initialValues={initialUser}
            validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              await toast.promise(
                initLogin(values),
                {
                  loading: t('loading'),
                  success: t('login-success'),
                  error: t('login-bad-credentials'),
                },
              );

              resetForm();
              setSubmitting(false);
            }}
          >
            {({
              touched,
              errors,
              isSubmitting,
              handleSubmit,
              handleChange,
            }) => (
              <Form className="px-0 text-center" onSubmit={handleSubmit}>
                <Form.Group controlId="username" className='py-3'>
                  <InputGroup className={touched.username && errors.username ? 'error' : 'my-3'}>
                    <InputGroup.Text className='form-faicon'>
                      <FontAwesomeIcon
                        icon={faEnvelope}
                      />
                    </InputGroup.Text>
                    <Form.Control
                      id="username"
                      className='login-form-control dark-form-control'
                      placeholder={t('account-id')}
                      onChange={handleChange}
                    />
                    <InputGroup.Text className='form-faicon'>
                    </InputGroup.Text>
                  </InputGroup>
                  {(touched.username && errors.username) && <div className="error-message">{errors.username}</div>}
                </Form.Group>
                <InputGroup className={touched.username && errors.username ? 'error' : 'my-3'}>
                  <InputGroup.Text className='form-faicon'>
                    <FontAwesomeIcon
                      icon={faLock}
                    />
                  </InputGroup.Text>
                  <FormControl
                    id="password"
                    style={{ borderLeft: '0' }}
                    type="password"
                    className='login-form-control dark-form-control'
                    placeholder={t('password')}
                    onChange={handleChange}
                  />
                  <InputGroup.Text className='form-faicon'>
                  </InputGroup.Text>
                </InputGroup>
                {(touched.password && errors.password) && <div className="error-message">{errors.password}</div>}
                <div className='d-flex justify-content-around'>
                  <div className='align-self-center text-uppercase text-white fw-bold'>
                    <small><Link to="/recovery/password">{t('forgot-password-short')}</Link></small>
                  </div>
                  <div>
                    <Button type="submit" size="sm" className="login-button" disabled={isSubmitting}>
                      <span className='pe-3'>{t('login')}</span>
                      <FontAwesomeIcon icon={faArrowRight} />
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </Container>
  );
};

export default UserPanel;
