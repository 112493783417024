import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import toast from 'react-hot-toast';
import {
  Alert, Button, Col, Container, Form, Row,
} from 'react-bootstrap';
import { post } from '../adapters/xhr';

const UserVoteFirst = (vote) => {
  const { t } = useTranslation();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const performSocialIdRecovery = async () => {
    try {
      if (!executeRecaptcha) {
        return;
      }
      const token = await executeRecaptcha('vote');
      await post('/api/user/account/vote', {
        token,
        vote,
      });
      if (vote.id === 1) {
        window.open(`${process.env.REACT_APP_VOTE1_URL}`);
      } else if (vote.id === 2) {
        window.open(`${process.env.REACT_APP_VOTE2_URL}`);
      } else if (vote.id === 3) {
        window.open(`${process.env.REACT_APP_VOTE3_URL}`);
      } else if (vote.id === 4) {
        window.open(`${process.env.REACT_APP_VOTE4_URL}`);
      } else if (vote.id === 5) {
        window.open(`${process.env.REACT_APP_VOTE5_URL}`);
      }
      toast.success(t('vote-process-success'));
    } catch (e) {
      if (e.response.status === 400) {
        toast.error(t('vote-process-delay-24h'));
      } else {
        toast.error(t('process-error'));
      }
    }
  };

  return (
    <Container className="mt-4 text-center">
      <Row>
        <Col>
          <Alert variant="warning">
            {t(`vote4buff-in-text${vote.id}`)}
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col className="text-center">
          <Form className="py-2 text-center">
            <Button size="sm" className="default-button" onClick={async () => {
              await performSocialIdRecovery();
            }}>
              {t('vote')}
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default UserVoteFirst;
