import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import './NewsDetailed.scss';

const NewsDetailed = (props) => {
  const { data } = props;

  const { t } = useTranslation();

  return (
    <article className="pt-3 pb-4">
      <div className="d-flex justify-content-between">
        <span className="h5" style={{ color: '#d7a359' }}>
          {data.title}
        </span>
        <span>
          <small style={{ color: '#d7a359' }}>{data.date}</small>
        </span>
      </div>
      <div className="text-center">
        <Image className="announcement-image" src={`${process.env.REACT_APP_API_SERVER_URL}/assets/uploads/${data.image}`} />
      </div>
      <div className="mt-4 mx-4">
        <blockquote className="blockquote">
          <p className="mb-0"><small>{data.content.length > 250 ? `${data.content.substring(0, 250)}...` : data.content}</small></p>
            <hr />
            <p>
              <footer className="blockquote-footer text-right">{t('king-regards')} <cite title="Source Title">{t('your-team')}</cite>
              </footer>
            </p>
        </blockquote>
      </div>
    </article>
  );
};

export default NewsDetailed;
