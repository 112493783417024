import React, { useState } from 'react';
import {
  Col, Container, Nav, Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

import AdminGlobalSettings from './AdminGlobalSettings';
import AdminNewsSettings from './AdminNewsSettings';
import AdminDownloadSettings from './AdminDownloadsSettings';

const AdminProfile = () => {
  const { t } = useTranslation();

  const [selectedNav, setSelectedNav] = useState('web');
  const [selectedSettingsPage, setSelectedSettingsPage] = useState(null);

  const renderSelectedNav = () => {
    switch (selectedNav) {
      case 'web': return (
        <Nav>
          <Nav.Item className="choose-settings-navbar-item">
            <Nav.Link eventKey="global" onClick={() => { setSelectedSettingsPage('web-global'); }}>{t('global-settings')}</Nav.Link>
          </Nav.Item>
          <Nav.Item className="choose-settings-navbar-item">
            <Nav.Link eventKey="news" onClick={() => { setSelectedSettingsPage('web-news'); }}>{t('announcements')}</Nav.Link>
          </Nav.Item>
          <Nav.Item className="choose-settings-navbar-item">
            <Nav.Link eventKey="download" onClick={() => { setSelectedSettingsPage('web-downloads'); }}>{t('download-links')}</Nav.Link>
          </Nav.Item>
        </Nav>
      );
      default: return <div />;
    }
  };

  const renderSelectedSettingsPage = () => {
    switch (selectedSettingsPage) {
      case 'web-global': return <AdminGlobalSettings />;
      case 'web-news': return <AdminNewsSettings />;
      case 'web-downloads': return <AdminDownloadSettings />;
      default: return <div />;
    }
  };

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <Container className="py-3">
        <div className="main-panel-title text-center py-4 mb-3">
          {t('admin-panel')}
        </div>
        <Row className="mb-3">
          <Col>
            <Nav fill defaultActiveKey="web">
              <Nav.Item className="choose-settings-navbar-item">
                <Nav.Link eventKey="web" onClick={() => { setSelectedNav('web'); }}>{t('website-settings')}</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            { renderSelectedNav() }
          </Col>
        </Row>
        { renderSelectedSettingsPage() }
      </Container>
    </motion.div>
  );
};

export default AdminProfile;
