import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  Alert, Col, Container, Row,
} from 'react-bootstrap';
import { motion } from 'framer-motion';

import { post } from '../adapters/xhr';

const UserChangeEmailOldConfirm = () => {
  const { t } = useTranslation();
  const { username, uniqueKey } = useParams();

  const [validationStatus, setValidationStatus] = useState(0);

  // Check if unique key is valid for the email change process
  useEffect(async () => {
    try {
      await post('/api/user/account/email/validate', {
        username,
        uniqueKey,
      });

      setValidationStatus(1);
    } catch (e) {
      setValidationStatus(-1);
    }
  }, []);

  switch (validationStatus) {
    case 1: return (
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
        <Container>
          <div className="main-panel-title text-center text-capitalize py-4 mb-3">
            {t('change-email')}
          </div>
          <Row>
            <Col>
              <Alert variant="success">
                <Alert.Heading>Success!</Alert.Heading>
                <p>
                  Aww yeah, the provided username and secret key combination is correct.
                </p>
                <hr />
                <p className="mb-0">
                  Please verify your new e-mail address,
                  because we&lsquo;ve sent an e-mail to finish the process!
                </p>
              </Alert>
            </Col>
          </Row>
        </Container>
      </motion.div>
    );
    case -1: return (
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
        <Container>
          <div className="main-panel-title text-center text-capitalize py-4 mb-3">
            {t('change-email')}
          </div>
          <Row>
            <Col>
              <Alert variant="danger">
                <Alert.Heading>Aw, snap :(</Alert.Heading>
                <p>
                  The username and secret key you provided is not correct
                  or it might have already expired, having a time limit of 24h.
                </p>
                <p>
                  If that&lsquo;s the case, please try to
                  reset your password again, and it should work next time!
                </p>
                <hr />
                <p className="mb-0">
                  If you think this error is wrong,
                  please contact a staff member as soon as possible.
                </p>
              </Alert>
            </Col>
          </Row>
        </Container>
      </motion.div>
    );
    default: return <div/>;
  }
};

export default UserChangeEmailOldConfirm;
