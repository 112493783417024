import React from 'react';
import {
  Route, Routes, useLocation,
} from 'react-router-dom';
import {
  Col, Container, Image, Row,
} from 'react-bootstrap';
import { AnimatePresence } from 'framer-motion';

import { useTranslation } from 'react-i18next';
import InteractiveButtons from './InteractiveButtons';
import Slider from './Carousel';
import UserPanel from './UserPanel';
import News from './News';
import Register from './Register';
import Ranking from './Ranking';
import Download from './Download';
import PresentationDE from './PresentationDE';
import Privacy from './Privacy';
import Refunds from './Refunds';
import Terms from './Terms';
import AdminProfile from './AdminProfile';
import UserProfile from './UserProfile';
import Donate from './Donate';
import Vote from './Vote';
import AuthenticatedRoute from './AuthentificatedRoute';
import NotAuthenticatedRoute from './NotAuthenticatedRoute';
import AdminRoute from './AdminRoute';
import ResetPassword from './ResetPassword';
import ResetPasswordConfirm from './ResetPasswordConfirm';
import ResetUsername from './ResetUsername';
import UserChangeEmailOldConfirm from './UserChangeEmailOldConfirm';
import UserChangeEmailNewConfirm from './UserChangeEmailNewConfirm';
import RegisterConfirm from './RegisterConfirm';
import Login from './Login';
import NotFound from './NotFound';
import './Main.scss';
// import Statistics from './Statistics';
import RankingSidePlayers from './RankingSidePlayers';
import RankingSideGuilds from './RankingSideGuilds';
import Home from './Home';

import SuraImage from '../assets/images/sura_image.png';
import CharFace1 from '../assets/images/char_face_1.png';
import CharFace2 from '../assets/images/char_face_2.png';
import CharFace3 from '../assets/images/char_face_3.png';
import CharFace4 from '../assets/images/char_face_4.png';
import LineDiscord from '../assets/images/line_discord.png';
import LineYoutube from '../assets/images/youtube_line.png';
import ModalWrapper from './ModalWrapper';

const Main = () => {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <Container className="main-padding color-primary-text">
      <Row className="g-0 main-bg">
        <Col lg={3}>
          <Container>
            <Row className="g-0">
              <Col className="login-panel">
                <UserPanel />
              </Col>
            </Row>
            <Row className="g-0">
              <Col>
                <RankingSidePlayers />
              </Col>
            </Row>
          </Container>
        </Col>
        <Col lg={6}>
          <Container>
            <Row>
              <Col>
                <div>
                  <Slider />
                </div>
                <div className='line my-3' />
              </Col>
            </Row>
            <Row className="g-0">
              <Col>
                <AnimatePresence exitBeforeEnter initial={false}>
                  <Routes location={location} key={location.pathname}>
                    <Route exact path="/" element={ <Home /> } />
                    <Route exact path="/news" element={ <News /> } />
                    <Route
                      exact path="/register"
                      element={
                        <NotAuthenticatedRoute>
                          <ModalWrapper>
                            <Register />
                          </ModalWrapper>
                        </NotAuthenticatedRoute>
                      }
                    />
                    <Route
                      exact path="/register/confirm/:username/:uniqueKey"
                      element={
                        <NotAuthenticatedRoute>
                          <RegisterConfirm />
                        </NotAuthenticatedRoute>
                      }
                    />
                    <Route exact path="/ranking" element={ <Ranking /> } />
                    <Route exact path="/download" element={ <Download /> } />
                    <Route exact path="/presentation" element={ <PresentationDE /> } />
                    <Route exact path="/privacy" element={ <Privacy /> } />
                    <Route exact path="/refunds" element={ <Refunds /> } />
                    <Route exact path="/terms" element={ <Terms /> } />
                    <Route
                      path="/login"
                      element={
                        <NotAuthenticatedRoute>
                          <Login />
                        </NotAuthenticatedRoute>
                      }
                    />
                    <Route
                      exact path="/user/profile"
                      element={
                        <AuthenticatedRoute>
                          <UserProfile />
                        </AuthenticatedRoute>
                      }
                    />
                    <Route
                      exact path="/user/donate"
                      element={
                        <AuthenticatedRoute>
                          <Donate />
                        </AuthenticatedRoute>
                      }
                    />
                    <Route
                      exact path="/user/vote"
                      element={
                        <AuthenticatedRoute>
                          <Vote />
                        </AuthenticatedRoute>
                      }
                    />
                    <Route exact path="/user/email/validate/:username/:uniqueKey" element={UserChangeEmailOldConfirm} />
                    <Route exact path="/user/email/accept/:username/:uniqueKey" element={UserChangeEmailNewConfirm} />
                    <Route
                      exact path="/admin/profile"
                      element={
                        <AdminRoute>
                          <AdminProfile />
                        </AdminRoute>
                      }
                    />
                    <Route
                      exact path="/recovery/password"
                      element={
                        <NotAuthenticatedRoute>
                          <ResetPassword />
                        </NotAuthenticatedRoute>
                      }
                    />
                    <Route
                      exact path="/recovery/password/confirm/:username/:uniqueKey"
                      element={
                        <NotAuthenticatedRoute>
                          <ResetPasswordConfirm />
                        </NotAuthenticatedRoute>
                      }
                    />
                    <Route
                      exact path="/recovery/username"
                      element={
                        <NotAuthenticatedRoute>
                          <ResetUsername />
                        </NotAuthenticatedRoute>
                      }
                    />
                    <Route element={ <NotFound /> } />
                  </Routes>
                </AnimatePresence>
              </Col>
            </Row>
          </Container>
        </Col>
        <Col lg={3}>
          <Container>
            <Row className="g-0">
              <Col>
                <InteractiveButtons />
              </Col>
            </Row>
            <Row className="g-0">
              <Col>
                <RankingSideGuilds />
              </Col>
            </Row>
            <Row className="g-0">
            </Row>
          </Container>
        </Col>
      </Row>
      <Row className='g-0 py-3 main-bg'>
        <Col className='d-flex justify-content-between'>
          <div style={{ paddingTop: '110px' }}>
            <a href={process.env.REACT_APP_METIN2_TOPLIST} target='_blank' rel='noreferrer'>
              <div className='d-flex fontCourier text-uppercase'>
                <div className='d-flex flex-column align-self-center' style={{ color: '#b1a59f' }}>
                  <div className='ps-4'>
                    Metin2-Toplist
                  </div>
                  <Image style={{ marginTop: '-5px' }} src={LineDiscord} />
                </div>
                <div className='align-self-center ps-3'>
                  <Image src={CharFace1} />
                </div>
              </div>
            </a>
            <a href={process.env.REACT_APP_EPVP_PRESENTATION_URL} target='_blank' rel='noreferrer'>
              <div className='d-flex fontCourier text-uppercase'>
                <div className='d-flex flex-column align-self-center' style={{ color: '#b1a59f' }}>
                  <div className='ps-3'>
                    epvp {t('presentation')}
                  </div>
                  <Image style={{ marginTop: '-5px', paddingLeft: '65px' }} src={LineDiscord} />
                </div>
                <div className='align-self-center ps-3'>
                  <Image src={CharFace2} />
                </div>
              </div>
            </a>
          </div>
          <div>
            <Image src={SuraImage} />
          </div>
          <div style={{ paddingTop: '110px' }}>
            <a href={process.env.REACT_APP_YOUTUBE_URL} target='_blank' rel='noreferrer' title="Best Metin2 Pservers Toplist" data-id="2069">
              <div className='d-flex fontCourier text-uppercase' style={{ marginLeft: '-50px' }}>
                <div className='align-self-center ps-3'>
                  <Image src={CharFace3} />
                </div>
                <div className='d-flex flex-column align-self-center' style={{ color: '#b1a59f' }}>
                  <div style={{ paddingLeft: '65px' }}>
                    Metin2Pserver.net
                  </div>
                  <Image style={{ marginTop: '-5px' }} src={LineYoutube} />
                </div>
              </div>
            </a>
            <a href={process.env.REACT_APP_METIN2DOWNLOADS} target='_blank' rel='noreferrer'>
              <div className='d-flex fontCourier text-uppercase' style={{ marginLeft: '-90px' }}>
                <div className='align-self-center pe-3'>
                  <Image src={CharFace4}/>
                </div>
                <div className='d-flex flex-column align-self-center' style={{ color: '#b1a59f' }}>
                  <div className='ps-5'>
                    {t('m2-downloads')}
                  </div>
                  <Image style={{ marginTop: '-5px' }} src={LineYoutube}/>
                </div>
              </div>
            </a>
          </div>
        </Col>
      </Row>
      <Row className='g-0 py-3 triangle-main'>
        <Col>
          <div>sal</div>
          <div>sal</div>
          <div>sal</div>
          <div>sal</div>
        </Col>
      </Row>
    </Container>
  );
};

export default Main;
