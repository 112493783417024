import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Footer = () => {
  const { t } = useTranslation();

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <footer className="text-muted">
      <Container className="text-white text-center py-4">
        <Row className='pb-5'>
          <Col className='d-flex justify-content-center text-uppercase'>
            <Link className='footer-link px-3' to='/' onClick={scrollToTop}>{t('first-page')}</Link>
            <Link className='footer-link px-3' to='/news' onClick={scrollToTop}>{t('news')}</Link>
            <Link className='footer-link px-3' to='/privacy' onClick={scrollToTop}>{t('imprint')}</Link>
            <Link className='footer-link px-3' to='/privacy' onClick={scrollToTop}>{t('privacy')}</Link>
            <Link className='footer-link px-3' to='/refunds' onClick={scrollToTop}>{t('refunds')}</Link>
          </Col>
        </Row>
        <Row>
          <Col>
            <div style={{ color: '#938b8b', fontSize: '18px' }}>
              © 2022, United Empire All rights reserved.
            </div>
            <div style={{ color: '#7a7a7a', fontSize: '10px' }} className='pt-4'>
              Developed by <a href="https://leheldev.com" target="_blank" rel="noreferrer">Lehel</a> and designed by
              Alina.Ps for
            </div>
            <div style={{ color: '#7a7a7a', fontSize: '10px' }}>
              United Empire
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
