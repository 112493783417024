import React from 'react';
import { motion } from 'framer-motion';
import { Col, Row } from 'react-bootstrap';

const Home = () => (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <Row>
        <Col className="d-flex justify-content-center">
          <div className="news-one-bg">
            {/* Embed an autoplay video */}
            <video autoPlay muted loop className="video-bg">
              <source src="https://cdn.unitedmt2.com/news_bg1.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex justify-content-center">
          <div className="news-two-bg">
            {/* Embed an autoplay video */}
            <video autoPlay muted loop className="video-bg">
              <source src="https://cdn.unitedmt2.com/news_bg2.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </Col>
      </Row>
    </motion.div>
);

export default Home;
