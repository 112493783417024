import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Alert,
  Col, Container, Row,
} from 'react-bootstrap';
import { motion } from 'framer-motion';
import AuthProvider from '../contexts/AuthProvider';

const Donate = () => {
  const { t } = useTranslation();
  const { account } = useContext(AuthProvider.context);
  const { user } = account;

  return (
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
        <Container className="py-3">
          <div className="main-panel-title text-center text-capitalize py-4 mb-3">
            {t('donate')}
          </div>
          <Row className="mb-3" >
            <Col className="text-center">
              <Alert variant="warning">
                {t('donate-warn')}
              </Alert>
            </Col>
            <Col>
              <iframe src={`https://api.paymentwall.com/api/subscription/?key=e7a678e09f238dbdc046edd74c263fff&uid=${user.id}&widget=p1_2`} width='650' height='473'></iframe>
            </Col>
          </Row>
        </Container>
      </motion.div>
  );
};

export default Donate;
