import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import {
  Alert, Button, Col, Container, Form, InputGroup, Row,
} from 'react-bootstrap';
import { Formik } from 'formik';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowCircleRight, faEye, faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { get, post } from '../adapters/xhr';

const initialUser = {
  username: '',
  password: '',
  passwordVerify: '',
  email: '',
  realName: '',
  socialId: '',
  acceptTerms: false,
};

const Register = () => {
  const { t } = useTranslation();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [visiblePassword, setVisiblePassword] = useState(false);
  const [error, setError] = useState('');
  const [registerStatus, setRegisterStatus] = useState(true);

  const performRegister = async (values, token) => {
    const {
      username, email, realName, socialId, password,
    } = values;

    await post('/api/auth/signup', {
      username,
      password,
      email,
      real_name: realName,
      social_id: socialId,
      token,
    });
  };

  const initRegister = async (values) => {
    if (!executeRecaptcha) {
      return;
    }

    const token = await executeRecaptcha('login');
    await performRegister(values, token);
  };

  useEffect(async () => {
    const { data } = await get('/api/global/register');
    setRegisterStatus(data);
  }, []);

  const validationSchema = yup.object().shape({
    username: yup
      .string()
      .matches(/^[A-Za-z\d]{2,16}$/, t('account-match'))
      .required(t('field-required')),
    password: yup
      .string()
      .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,16}$/, t('password-match'))
      .min(8, t('password-min'))
      .max(16, t('password-max'))
      .required(t('field-required')),
    passwordVerify: yup
      .string()
      .oneOf(
        [yup.ref('password'), null],
        t('passwords-not-match'),
      )
      .required(t('field-required')),
    email: yup
      .string()
      .email(t('email-format'))
      .required(t('field-required')),
    realName: yup
      .string()
      .matches(
        /^[A-Za-z0-9 ]{1,16}$/,
        t('name-format'),
      )
      .min(3)
      .max(30)
      .required(t('field-required')),
    socialId: yup
      .string()
      .matches(
        /^[0-9]{7}$/,
        t('social-id-format'),
      )
      .required(t('field-required')),
    acceptTerms: yup.boolean().oneOf([true], t('must-accept-terms')),
  });

  if (!registerStatus) {
    return (
      <Container>
        <div className="main-panel-title text-center text-capitalize py-4 mb-3">
          {t('register')}
        </div>
        <Row className="pt-3 text-center">
          <Col>
            <Alert variant="danger">
              <Alert.Heading>{t('hey_welcome')}</Alert.Heading>
              <p>
                {t('register-disabled')}
              </p>
              <hr />
              <p className="mb-0">
                {t('have_great_time')}
              </p>
              <blockquote className="blockquote">
                <footer className="blockquote-footer text-right">{t('please-try-again')} <cite title="Source Title">{t('your-team')}</cite>
                </footer>
              </blockquote>
            </Alert>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <Container>
        <div className="text-white text-center text-capitalize py-4 mb-3">
          {t('register')}
        </div>
        {error !== ''
        && <Row className="pt-3 text-center">
          <Col>
            <Alert variant="danger">
              <Alert.Heading>{t('register-error')}</Alert.Heading>
              <p>
                {t(error)}
              </p>
              <hr />
              <blockquote className="blockquote">
                <footer className="blockquote-footer text-right">{t('king-regards')} <cite title="Source Title">{t('your-team')}</cite>
                </footer>
              </blockquote>
            </Alert>
          </Col>
        </Row>}
        <Row className="mx-5 main-panel-content">
          <Col lg={12} className='d-flex justify-content-center'>
            <Formik
              initialValues={initialUser}
              validationSchema={validationSchema}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                setSubmitting(true);
                await toast.promise(
                  initRegister(values),
                  {
                    loading: t('loading'),
                    success: t('register-success'),
                    error: (err) => { if (err.response.data.message) setError(err.response.data.message); return t('register-error'); },
                  },
                );

                resetForm();
                setSubmitting(false);
              }}
            >
              {({
                touched,
                errors,
                isSubmitting,
                handleSubmit,
                handleChange,
              }) => (
                <Form className="pb-3" onSubmit={handleSubmit}>
                  <Form.Group controlId="username">
                    <Form.Label size="sm">{t('user-name')}</Form.Label>
                    <Form.Control
                      className={touched.username && errors.username ? 'dark-form-control error' : 'dark-form-control'}
                      size="sm"
                      placeholder={t('user-name')}
                      onChange={handleChange}
                    />
                    {(touched.username && errors.username) && <div className="error-message">{errors.username}</div>}
                  </Form.Group>

                  <Form.Group controlId="realName" className='py-3'>
                    <Form.Label>{t('real-name')}</Form.Label>
                    <Form.Control
                      className={touched.realName && errors.realName ? 'dark-form-control error' : 'dark-form-control'}
                      size="sm"
                      placeholder={t('real-name')}
                      onChange={handleChange}
                    />
                    {(touched.realName && errors.realName) && <div className="error-message">{errors.realName}</div>}
                  </Form.Group>

                  <Form.Group controlId="password" className='py-3'>
                    <Form.Label size="sm">{t('password')}</Form.Label>
                    <InputGroup>
                      <Form.Control
                        className={touched.password && errors.password ? 'dark-form-control error' : 'dark-form-control'}
                        size="sm"
                        type={visiblePassword ? 'text' : 'password'}
                        placeholder={t('password')}
                        onChange={handleChange}
                      />
                      <InputGroup.Text className='form-faicon'>
                        <FontAwesomeIcon
                          icon={visiblePassword ? faEye : faEyeSlash}
                          onClick={() => { setVisiblePassword(!visiblePassword); }}
                        />
                      </InputGroup.Text>
                    </InputGroup>
                    {(touched.password && errors.password) && <div className="error-message">{errors.password}</div>}
                  </Form.Group>

                  <Form.Group controlId="passwordVerify" className='py-3'>
                    <Form.Label>{t('password-verify')}</Form.Label>
                    <InputGroup>
                      <Form.Control
                        className={touched.passwordVerify && errors.passwordVerify ? 'dark-form-control error' : 'dark-form-control'}
                        size="sm"
                        type={visiblePassword ? 'text' : 'password'}
                        placeholder={t('password-verify')}
                        onChange={handleChange}
                      />
                      <InputGroup.Text className='form-faicon'>
                        <FontAwesomeIcon
                          icon={visiblePassword ? faEye : faEyeSlash}
                          onClick={() => { setVisiblePassword(!visiblePassword); }}
                        />
                      </InputGroup.Text>
                    </InputGroup>
                    {(touched.passwordVerify && errors.passwordVerify) && <div className="error-message">{errors.passwordVerify}</div>}
                  </Form.Group>

                  <Form.Group controlId="email" className='py-3'>
                    <Form.Label>{t('email-address')}</Form.Label>
                    <Form.Control
                      className={touched.email && errors.email ? 'dark-form-control error' : 'dark-form-control'}
                      size="sm"
                      type="email"
                      placeholder={t('email-address')}
                      onChange={handleChange}
                    />
                    {(touched.email && errors.email) && <div className="error-message">{errors.email}</div>}
                  </Form.Group>

                  <Form.Group controlId="socialId" className='py-3'>
                    <Form.Label>{t('character-deletion')}</Form.Label>
                    <Form.Control
                      className={touched.socialId && errors.socialId ? 'dark-form-control error' : 'dark-form-control'}
                      size="sm"
                      placeholder={t('character-deletion')}
                      onChange={handleChange}
                    />
                    {(touched.socialId && errors.socialId) && <div className="error-message">{errors.socialId}</div>}
                  </Form.Group>

                  <div className="text-center">
                    <Form.Group controlId="acceptTerms" className='py-3'>
                      <Form.Check
                        size="sm"
                        style={{ color: '#afafaf' }}
                        type="checkbox"
                        label={t('agree-terms')}
                        onChange={handleChange}
                      />
                      {(touched.acceptTerms && errors.acceptTerms) && <div className="error-message">{errors.acceptTerms}</div>}
                    </Form.Group>

                    <Button type="submit" size="sm" className="default-button" disabled={isSubmitting}>
                      {t('register')}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
        <Row className="text-center mt-2 mb-3">
          <Col>
            <small><FontAwesomeIcon icon={faArrowCircleRight}/> <Link className="text-link" to="/login">{t('go-to-login')}</Link></small>
          </Col>
        </Row>
      </Container>
    </motion.div>
  );
};

export default Register;
