import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import {
  Alert, Col, Container, Form, FormControl, Image, InputGroup, Row,
} from 'react-bootstrap';

import { get } from '../adapters/xhr';
import AuthProvider from '../contexts/AuthProvider';
import defaultUserProfileImage from '../assets/images/default-profile.png';

const UserProfileInfo = () => {
  const { t } = useTranslation();

  const { account } = useContext(AuthProvider.context);
  const { user } = account;

  const [banInfo, setBanInfo] = useState({ reason: '', proof: '', request_time: '' });
  const [accountInfo, setAccountInfo] = useState({ email: '', coins: 0, status: 'OK' });

  const getBanInfo = async () => {
    const { data } = await get('/api/user/account/blockinfo');
    data.request_time = DateTime.fromISO(data.request_time).toFormat('fff');

    setBanInfo(data);
  };

  const getAccountInfo = async () => {
    const { data } = await get('/api/user/account');
    setAccountInfo(data);

    if (data.status === 'BLOCK') {
      await getBanInfo();
    }
  };

  useEffect(async () => { await getAccountInfo(); }, []);

  return (
    <Container>
      {accountInfo.status === 'BLOCK' && <Row>
        <Col>
          <Alert variant="danger">
            <Alert.Heading>Your account is blocked!</Alert.Heading>
            <p>
              <strong>Reason:</strong> {banInfo.reason}
            </p>
            <p>
              <strong>Proof:</strong> <a href={banInfo.proof} target="_blank" rel="noreferrer">click</a>
            </p>
            <p>
              <strong>Date:</strong> {banInfo.request_time}
            </p>
            <hr />
            <p className="mb-0">
              If you think the decision is incorrect, please contact a staff member.
            </p>
          </Alert>
        </Col>
      </Row>}
      <Row className="mb-4">
        <Col lg={6}>
          <div className="d-flex flex-column align-items-center text-center py-4">
            <Image className="rounded-circle mb-3" width="180px" src={defaultUserProfileImage} />
            <small>{t('welcome')} {user.username}</small>
          </div>
        </Col>
        <Col lg={6}>
          <Form className="py-2 text-center">
            <InputGroup size="sm" className="mb-3">
              <InputGroup.Text className="dark-form-control">{t('user-name')}</InputGroup.Text>
              <FormControl
                disabled
                className="dark-form-control"
                value={user.username}
              />
            </InputGroup>

            <InputGroup size="sm" className="mb-3">
              <InputGroup.Text className="dark-form-control">{t('real-name')}</InputGroup.Text>
              <FormControl
                disabled
                className="dark-form-control"
                value={user.real_name}
              />
            </InputGroup>

            <InputGroup size="sm" className="mb-3">
              <InputGroup.Text className="dark-form-control">{t('email-address')}</InputGroup.Text>
              <FormControl
                disabled
                className="dark-form-control"
                value={accountInfo.email}
              />
            </InputGroup>

            <InputGroup size="sm" className="mb-3">
              <InputGroup.Text className="dark-form-control">{t('md-coins')}</InputGroup.Text>
              <FormControl
                disabled
                className="dark-form-control"
                value={accountInfo.coins}
              />
            </InputGroup>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default UserProfileInfo;
