import React from 'react';
import { useQuery } from 'react-query';
import {
  Col, Container, Image, Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

import { get } from '../adapters/xhr';
import MegaImage from '../assets/images/download/download-mega.png';
import TorrentImage from '../assets/images/download/download-torrent.png';
import MirrorImage from '../assets/images/download/download-mirror.png';
import GoogleImage from '../assets/images/download/download-drive.png';
import Loading from './Loading';

const Download = () => {
  const { t } = useTranslation();
  const {
    data, error, isLoading, isError,
  } = useQuery('downloads', () => get('/assets/cache/downloads.json'));

  if (isError) {
    return (
      <Row>
        <Col>
          {error}
        </Col>
      </Row>
    );
  }

  return (isLoading ? <Loading />
    : <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <Container>
        <div className="main-panel-title text-center text-capitalize py-4 mb-3">
          {t('download')}
        </div>
        <Row className="py-3 main-panel-content">
          <Col className="text-center">
            {data.data.map((it, index) => (
                <div key={index} className="d-flex justify-content-around py-3">
                  {it.name}
                  <Image className="download-img" src={(
                    it.type === 'mega' ? MegaImage
                      : it.type === 'torrent' ? TorrentImage
                        : it.type === 'google' ? GoogleImage
                          : MirrorImage)
                  } alt="" />
                  <a target="_blank" rel="noreferrer" className="text-uppercase text-decoration-none text-white" href={it.link}>{t('download')}</a>
                </div>
            ))}
          </Col>
        </Row>
      </Container>
    </motion.div>
  );
};

export default Download;
