import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import {
  Button, Col, Form, Image, ListGroup, Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';

import MegaImage from '../assets/images/download/download-mega.png';
import TorrentImage from '../assets/images/download/download-torrent.png';
import MirrorImage from '../assets/images/download/download-mirror.png';
import GoogleImage from '../assets/images/download/download-drive.png';
import { deleteReq, get, post } from '../adapters/xhr';

const AdminDownloadSettings = () => {
  const { t } = useTranslation();

  const [downloads, setDownloads] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [name, setName] = useState('');
  const [type, setType] = useState(null);
  const [link, setLink] = useState('');

  const getDownloads = async () => {
    const { data } = await get('/api/admin/downloads');

    const newData = data.map((item) => ({
      ...item,
      link: <a target="_blank" rel="noreferrer" className="text-uppercase text-decoration-none text-white" href={item.link}>{t('download')}</a>,
      type: <Image className="download-img" src={(
        item.type === 'mega' ? MegaImage
          : item.type === 'torrent' ? TorrentImage
            : item.type === 'google' ? GoogleImage
              : MirrorImage)
      } alt="" />,
    }));

    setDownloads(newData);
  };

  useEffect(async () => { await getDownloads(); }, []);

  const addNewDownload = async () => {
    try {
      await post('/api/admin/downloads', {
        name,
        type: type.value,
        link,
      });

      toast.success(t('add-download-success'));
      await getDownloads();
    } catch (error) {
      toast.error(t('add-download-error'));
    }
  };

  const deleteDownload = async (id) => {
    try {
      await deleteReq('/api/admin/downloads', {
        data: { id },
      });

      toast.success(t('delete-download-success'));
      await getDownloads();
    } catch (error) {
      toast.error(t('delete-download-error'));
    }
  };

  const columns = [
    { dataField: 'id', text: 'ID' },
    { dataField: 'name', text: t('name') },
    { dataField: 'type', text: t('type') },
    { dataField: 'link', text: 'Link' },
  ];

  const options = [
    { value: 'mega', label: 'MEGA.nz' },
    { value: 'torrent', label: 'Torrent' },
    { value: 'google', label: 'Google drive' },
    { value: 'mirror', label: 'Mirror' },
  ];

  const selectStyle = {
    control: (styles) => ({ ...styles, backgroundColor: '#1c0101', color: '#d7a359' }),
    placeholder: (styles) => ({ ...styles, color: '#da359' }),
    singleValue: (styles) => ({ ...styles, color: '#da359' }),
    option: (styles) => ({
      ...styles,
      backgroundColor: '#1c0101',
      color: '#d7a359',
    }),
  };

  const expandRow = {
    // eslint-disable-next-line react/display-name
    renderer: (row) => (
      <ListGroup className="text-left">
        <ListGroup.Item className="d-flex justify-content-between dark-input-group">
          <div className="w-100">
            <h5 className="mb-1">{t('download-delete')}</h5>
            <p className="mb-1">{t('permanent-action')}</p>
          </div>
          <Button size="sm" className="default-button align-self-center" onClick={ async () => { await deleteDownload(row.id); }}>{t('delete')}</Button>
        </ListGroup.Item>
      </ListGroup>
    ),
    onlyOneExpanding: true,
    expandByColumnOnly: true,
    expandColumnPosition: 'right',
    showExpandColumn: true,
    // eslint-disable-next-line react/display-name
    expandColumnRenderer: ({ expandable }) => (expandable
      && <Button size="sm" className="default-button" onClick={onclick}>
        {t('edit')}
      </Button>),
    // eslint-disable-next-line react/display-name
    expandHeaderColumnRenderer: () => (
      <div onClick={(e) => { e.stopPropagation(); }}>{t('actions')}</div>
    ),
  };

  return (
    <>
      <Row className="mb-3">
        <Col className="form-bordered-gold text-center">
          {showForm ? <Form>
            <Form.Group as={Row}>
              <Form.Label column sm={6}>
                {t('name')}
              </Form.Label>
              <Col sm={6}>
                <Form.Control className="dark-form-control" size="sm" placeholder="PATCHER" onChange={(e) => { setName(e.target.value); }} />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={6}>
                {t('type')}
              </Form.Label>
              <Col sm={6}>
                <Select
                  value={type}
                  onChange={(option) => { setType(option); }}
                  options={options}
                  styles={selectStyle}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={6}>
                {t('link')}
              </Form.Label>
              <Col sm={6}>
                <Form.Control className="dark-form-control" size="sm" placeholder="https://example.com/" onChange={(e) => { setLink(e.target.value); }} />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="text-center">
              <Col sm={12}>
                <Button size="sm" className="default-button" onClick={ async () => { await addNewDownload(); setShowForm(false); }}>
                  {t('submit')}
                </Button>
              </Col>
            </Form.Group>
          </Form> : <Button size="sm" className="default-button" onClick={() => { setShowForm(true); }}>
            {t('add-download')}
          </Button>}
        </Col>
      </Row>
      <Row>
        <Col className="text-center">
          <BootstrapTable noDataIndication={() => t('no-data-to-display')}
            bordered={false}
            bootstrap4={true}
            classes='table-data'
            headerClasses='color-gold'
            bodyClasses='border-0'
            keyField='id'
            data={downloads}
            columns={columns}
            expandRow={ expandRow }
            pagination={paginationFactory({ prePageText: t('previous'), nextPageText: t('next'), hideSizePerPage: true })}
          />
        </Col>
      </Row>
    </>);
};

export default AdminDownloadSettings;
