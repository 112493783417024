import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  Container, Nav, Navbar, Image,
} from 'react-bootstrap';

import AuthProvider from '../contexts/AuthProvider';
import './Header.scss';
import MiniIcons from './MiniIcons';
import NavbarIcon from '../assets/images/navbar_icon.png';

const Header = () => {
  const { t } = useTranslation();
  const { account } = useContext(AuthProvider.context);

  return (
    <Navbar className="custom-navbar" variant="dark" expand="lg">
      <MiniIcons />
      <Container className='header-margin-resolver'>
        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="d-flex justify-content-center w-100" justify="true">
            <div className='d-flex nav-link-custom px-5'>
              <div>
                <Image src={NavbarIcon} />
              </div>
              <Nav.Link as={Link} to="/">
                <div className='fontOptimus'>
                  {t('home')}
                </div>
                <div className='fontJapanase cursor-pointer-hover text-center'>
                  home
                </div>
              </Nav.Link>
            </div>
            <div className='d-flex nav-link-custom px-5'>
              <div>
                <Image src={NavbarIcon} />
              </div>
              <Nav.Link as={Link} to="/download">
                <div className='fontOptimus'>
                  {t('download')}
                </div>
                <div className='fontJapanase cursor-pointer-hover text-center'>
                  down
                </div>
              </Nav.Link>
            </div>
            <div className='d-flex nav-link-custom px-5'>
              <div>
                <Image src={NavbarIcon} />
              </div>
              <Nav.Link as={Link} to={account.auth ? '/user/profile' : '/register'}>
                <div className='fontOptimus'>
                  {account.auth ? t('user-panel') : t('register')}
                </div>
                <div className='fontJapanase cursor-pointer-hover text-center'>
                  regi
                </div>
              </Nav.Link>
            </div>
            <div className='d-flex nav-link-custom px-5'>
              <div>
                <Image src={NavbarIcon} />
              </div>
              <Nav.Link href={process.env.REACT_APP_DISCORD_URL} target="_blank">
                <div className='fontOptimus'>
                  discord
                </div>
                <div className='fontJapanase cursor-pointer-hover text-center'>
                  disc
                </div>
              </Nav.Link>
            </div>
            <div className='d-flex nav-link-custom px-5'>
              <div>
                <Image src={NavbarIcon} />
              </div>
              <Nav.Link href={process.env.REACT_APP_PRESENTATION_URL} target="_blank">
                <div className='fontOptimus'>
                  {t('presentation')}
                </div>
                <div className='fontJapanase cursor-pointer-hover text-center'>
                  pres
                </div>
              </Nav.Link>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
